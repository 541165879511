@import url('https://fonts.googleapis.com/css2?family=Space+Grotesk:wght@300;400;500;600;700&display=swap');
/* @import url('https://fonts.googleapis.com/css2?family=Dosis:wght@200;300;400;500;600;700;800&display=swap'); */

* {
  box-sizing: border-box;
}

:root {
  background-color: black;
}

body {
  margin: 0;
  font-family: 'Space Grotesk';
  /* font-family: 'Dosis', sans-serif; */
  background-color: white;
  height: 100%;
  min-height: 100%;
}

h1 {
  margin-top: 0;
}

ul {
  margin: 0;
  padding: 0;
}

p {
  margin: 0;
}

button {
  background-color: black;
  color: white;
  border: none;
  cursor: pointer;
  outline: none;
}

a {
  color: white;
  text-decoration: none;
}
